import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import type { UserManagerSettings } from 'oidc-client-ts';
import { WINDOW } from '../core.symbols';
import { AuthTokenInterceptor } from './auth-token.interceptor';
import { AuthService } from './auth.service';
import { oidcInitializerFactory } from './oidc-initializer.factory';
import { OIDC_SETTINGS } from './symbols';

@NgModule({})
export class OidcModule {
  static forRoot(settings: UserManagerSettings): ModuleWithProviders<OidcModule> {
    return {
      ngModule: OidcModule,
      providers: [
        { provide: OIDC_SETTINGS, useValue: settings },
        {
          provide: APP_INITIALIZER,
          useFactory: oidcInitializerFactory,
          multi: true,
          deps: [AuthService, Router, WINDOW],
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
      ],
    };
  }
}
