import { BooleanInput } from '@angular/cdk/coercion';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { WINDOW } from '@base-frontend/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InputBoolean } from 'ng-zorro-antd/core/util';
import { BehaviorSubject, EMPTY, fromEvent, interval, merge, of } from 'rxjs';
import { debounceTime, filter, switchMap, take } from 'rxjs/operators';
import { UiIntersectObserverService } from './intersect-observer.service';

@UntilDestroy()
@Directive({
  selector: '[uiFitViewport]',
  exportAs: 'uiFitViewport',
})
export class UiFitViewportDirective implements OnInit {
  static ngAcceptInputType_uiFitViewport: BooleanInput;

  private _enabled$ = new BehaviorSubject(false);
  @Input()
  @InputBoolean()
  set uiFitViewport(enabled: BooleanInput) {
    this._enabled$.next(!!enabled);
  }

  @HostBinding('style.height') height: string;

  constructor(
    private _elRef: ElementRef<HTMLElement>,
    private _cdr: ChangeDetectorRef,
    private _intersectService: UiIntersectObserverService,
    @Inject(WINDOW) private _window: Window,
    @Inject(DOCUMENT) private _document: Document,
  ) {}

  ngOnInit() {
    const windowResize$ = fromEvent(this._window, 'resize').pipe(debounceTime(50));
    const scrollbarVisible$ = interval(5).pipe(
      take(10),
      filter(() => this.scrollbarVisible()),
    );

    this._enabled$
      .pipe(
        switchMap(enabled => {
          if (enabled) {
            return merge(of(true), scrollbarVisible$, windowResize$);
          }
          this.height = null;
          return EMPTY;
        }),
        untilDestroyed(this),
      )
      .subscribe(() => this.updateHeight());
  }

  private scrollbarVisible() {
    return this._document.body.scrollHeight > this._document.body.clientHeight;
  }

  private updateHeight() {
    const rect = this._elRef.nativeElement.getBoundingClientRect();
    const top = rect.top + this._window.scrollY;

    this.height = `calc(100vh - ${top + this.computeSpaceBelowElement()}px)`;
    this._cdr.markForCheck();
  }

  private computeSpaceBelowElement() {
    let space = 0;
    let parent = this._elRef.nativeElement.parentElement;

    while (true) {
      if (!parent || parent === this._window.document.body) {
        return space;
      }

      space += parseFloat(this._window.getComputedStyle(parent).paddingBottom || '0');
      parent = parent.parentElement;
    }
  }
}
