import { Injectable, TemplateRef } from '@angular/core';
import {
  NzNotificationContainerComponent,
  NzNotificationData,
  NzNotificationDataOptions,
  NzNotificationService,
} from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends NzNotificationService {
  success(title: string, content: string, options?: NzNotificationDataOptions) {
    return this.createNotification({ type: 'success', title, content }, options);
  }

  error(title: string, content: string, options?: NzNotificationDataOptions) {
    return this.createNotification({ type: 'error', title, content }, options);
  }

  info(title: string, content: string, options?: NzNotificationDataOptions) {
    return this.createNotification({ type: 'info', title, content }, options);
  }

  warning(title: string, content: string, options?: NzNotificationDataOptions) {
    return this.createNotification({ type: 'warning', title, content }, options);
  }

  blank(title: string, content: string, options?: NzNotificationDataOptions) {
    return this.createNotification({ type: 'blank', title, content }, options);
  }

  create(
    type: NzNotificationData['type'],
    title: string,
    content: string,
    options?: NzNotificationDataOptions,
  ) {
    return this.createNotification({ type, title, content }, options);
  }

  template(template: TemplateRef<{}>, options?: NzNotificationDataOptions) {
    return this.createNotification({ template }, options);
  }

  private createNotification(message: NzNotificationData, options?: NzNotificationDataOptions) {
    this.container = this.withContainer(NzNotificationContainerComponent);

    const wordsInMessageCount = (String(message.title || '') + String(message.content || '')).split(
      ' ',
    ).length;
    options = options ? { ...options } : {};
    options.nzDuration = options.nzDuration ?? Math.max(7000, 0.8 * wordsInMessageCount);

    return this.container.create({
      ...message,
      ...{
        createdAt: new Date(),
        messageId: this.generateMessageId(),
        options,
      },
    });
  }
}
