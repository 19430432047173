import { BehaviorSubject, fromEvent, merge, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';

const DEFAULT_OPTIONS: IStorageSubjectOptions = {
  TwoWaySync: false,
  Storage: localStorage,
};

export class StorageSubject<T> extends Observable<T> {
  private _subject$: BehaviorSubject<T>;
  private _storageKey$: Observable<string>;
  private _options: IStorageSubjectOptions;
  private _savePending: boolean;

  constructor(storageKey: Observable<string> | string, value: T, options = DEFAULT_OPTIONS) {
    super();
    this._subject$ = new BehaviorSubject<T>(value);
    this._storageKey$ =
      typeof storageKey === 'string' ? of(storageKey) : storageKey.pipe(distinctUntilChanged());
    this._options = { ...DEFAULT_OPTIONS, ...options };

    // TwoWaySync only works with localStorage
    if (this._options.Storage !== localStorage) {
      this._options.TwoWaySync = false;
    }

    (this as any).source = this.createSourceObservable(value);
  }

  get value(): T {
    return this._subject$.getValue();
  }

  getValue(): T {
    return this._subject$.getValue();
  }

  next(value: T) {
    this._savePending = true;
    return this._subject$.next(value);
  }

  asObservable(): Observable<T> {
    const observable = new Observable<T>();
    (observable as any).source = this;
    return observable;
  }

  private createSourceObservable(defaultValue: T): Observable<T> {
    const fromStorage$ = (key: string): Observable<T> => {
      return fromEvent<StorageEvent>(window, 'storage').pipe(
        filter(event => event.key === key && event.newValue !== null),
        map(event => JSON.parse(event.newValue)),
        tap(value => {
          // hack to update subject value without emitting event
          (this._subject$ as any)._value = value;
        }),
      );
    };

    return this._storageKey$.pipe(
      tap(key => this.loadFromStorage(key, defaultValue)),
      switchMap(key => {
        const subject$ = this._subject$.pipe(
          tap(value => {
            if (this._savePending) {
              this.saveToStorage(key, value);
              this._savePending = false;
            }
          }),
        );
        return this._options.TwoWaySync ? merge(subject$, fromStorage$(key)) : subject$;
      }),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private saveToStorage(key: string, value: T) {
    this._options.Storage.setItem(key, JSON.stringify(value));
  }

  private loadFromStorage(key: string, defaultValue: T) {
    const value = this._options.Storage.getItem(key);
    let parsedValue;

    if (value !== null) {
      try {
        parsedValue = JSON.parse(value);
      } catch (e) {
        parsedValue = value;
      }
    }

    this._subject$.next(parsedValue ?? defaultValue);
  }
}

export interface IStorageSubjectOptions {
  // Listens to changes from local storage. Only works with localStorage (Default: false)
  TwoWaySync?: boolean;
  // Storage to persist to (Default: localStorage)
  Storage?: Storage;
}
