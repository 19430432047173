import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {
  CommandQueryService,
  objEmpty,
  objReplaceStringInfinity,
  WINDOW,
} from '@base-frontend/core';
import { IPage } from '@base-frontend/ui';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { IMetaData, IPageQuery, IPageQueryPayload, PAGE_QUERY_DEFAULTS } from './symbols';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  readonly server = 'Performance';

  private _pageQuery$ = new BehaviorSubject<IPageQueryPayload>({} as IPageQueryPayload);
  pageQuery$ = this._pageQuery$.asObservable();

  templateId$ = this.pageQuery$.pipe(
    map(page => page.TemplateId),
    distinctUntilChanged(),
  );

  private _page$ = new BehaviorSubject<IPage>(undefined);
  page$ = this._page$.pipe(distinctUntilChanged());
  pageId$ = this.page$.pipe(
    map(p => p?.PageId),
    distinctUntilChanged(),
  );
  pageNotFound$ = this.page$.pipe(map(p => p === null));

  private _metaData$ = new BehaviorSubject<{ [key: string]: any }>({});
  private _ignoreRouteChange = false;

  get pageQuery() {
    return this._pageQuery$.getValue();
  }

  get pageId() {
    return this._page$.getValue()?.PageId;
  }

  get templateId() {
    return this.pageQuery.TemplateId;
  }

  constructor(
    private _commandQuery: CommandQueryService,
    private _http: HttpClient,
    private _router: Router,
    @Inject(WINDOW) private _window: Window,
  ) {
    this.pageQuery$
      .pipe(
        filter(query => !objEmpty(query)),
        switchMap(query => this.getPage(query)),
      )
      .subscribe(
        page => {
          if (page) {
            page.PageId = createPageId(this.pageQuery);
            page.Widgets?.forEach(w => {
              w.Id = [w.Type, w.Title, w.Label].filter(x => x).join('_');
            });
          }

          return this._page$.next(page?.Widgets ? page : null);
        },
        () => this._page$.next(null),
      );

    this.templateId$
      .pipe(
        filter(templateId => !!templateId),
        switchMap(this.fetchMeta.bind(this)),
      )
      .subscribe(metaData => this._metaData$.next(objReplaceStringInfinity(metaData)));
  }

  queryPage(payload: Partial<IPageQueryPayload>) {
    this._pageQuery$.next({
      TemplateId: payload.TemplateId || this.templateId,
      PageId: !payload.PageId && !payload.Tool ? PAGE_QUERY_DEFAULTS.PageId : payload.PageId,
      Tool: payload.Tool || '',
      Sensors: (payload.Sensors || PAGE_QUERY_DEFAULTS.Sensors).sort(),
      Components: (payload.Components || PAGE_QUERY_DEFAULTS.Components).sort(),
      DiagramType: payload.DiagramType || PAGE_QUERY_DEFAULTS.DiagramType,
    });
  }

  navigate(payload: Partial<IPageQueryPayload>, merge = false) {
    if (merge) {
      payload = { ...this.pageQuery, ...payload };
    }

    const queryParams: any = {};

    if (payload.Tool) {
      queryParams.Tool = payload.Tool;
      queryParams.DiagramType = payload.DiagramType || PAGE_QUERY_DEFAULTS.DiagramType;
    }

    this.queryPage(payload);
    this._ignoreRouteChange = true;
    this._router.navigate(['/', payload.TemplateId || this.templateId, payload.PageId || ''], {
      queryParams,
    });
  }

  onRouteChange(routeSnapshot: ActivatedRouteSnapshot) {
    const { params, queryParams } = routeSnapshot;

    if (!this._ignoreRouteChange) {
      this.queryPage({
        TemplateId: params.TemplateId,
        PageId: params.PageId,
        Tool: queryParams.Tool,
        DiagramType: queryParams.DiagramType,
      });
    }

    this._ignoreRouteChange = false;
  }

  metaDataSnapshot() {
    return this._metaData$.getValue();
  }

  private getPage(payload: IPageQueryPayload) {
    return this._commandQuery.sendQuery<IPage>(this.server, <IPageQuery>{
      Key: 'Page',
      ...payload,
    });
  }

  private fetchMeta(templateId: string) {
    return this._commandQuery.sendFetch<IMetaData>(this.server, {
      Key: 'MetaFetch',
      TemplateId: templateId,
    });
  }
}

function createPageId(pageQuery: IPageQueryPayload) {
  const { TemplateId, PageId, Tool, Sensors, Components, DiagramType } = pageQuery;
  const pageId = PageId || Tool || '';
  return `[TemplateId=${TemplateId}] [PageId=${pageId}] [Sensors=[${Sensors?.toString()}]] [Components=[${Components?.toString()}]] [DiagramType=${DiagramType}]`;
}
