import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiGridListHighlightComponent } from './grid-list-highlight.component';
import { UiGridListComponent } from './grid-list.component';
import { UiGridListItemComponent } from './grid-list-item.component';

@NgModule({
  declarations: [UiGridListComponent, UiGridListItemComponent, UiGridListHighlightComponent],
  exports: [UiGridListComponent, UiGridListItemComponent],
  imports: [CommonModule, DragDropModule],
})
export class UiGridListModule {}
