import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { UiIntersectObserverService } from './intersect-observer.service';

@UntilDestroy()
@Directive({
  selector: '[uiIntersectObserver]',
  exportAs: 'uiIntersectObserver',
})
export class UiIntersectObserverDirective implements OnInit, OnDestroy {
  @Output('uiIntersectObserver') intersect = new EventEmitter<IntersectionObserverEntry>();

  intersect$: Observable<IntersectionObserverEntry>;

  constructor(private _elRef: ElementRef, private _observerService: UiIntersectObserverService) {}

  ngOnInit() {
    this.intersect$ = this._observerService.observe(this._elRef.nativeElement);

    this.intersect$.pipe(untilDestroyed(this)).subscribe(entry => this.intersect.emit(entry));
  }

  ngOnDestroy() {
    this._observerService.unobserve(this._elRef.nativeElement);
  }
}
