import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { UiDirectivesModule } from '../directives';
import { UiLoadWidgetDirective } from './load-widget.directive';
import { UiWidgetBodyComponent } from './widget-body/widget-body.component';
import { UiWidgetErrorComponent } from './widget-error/widget-error.component';
import { UiWidgetHeaderComponent } from './widget-header/widget-header.component';
import { UiWidgetSectionComponent } from './widget-section/widget-section.component';
import { UiWidgetComponent } from './widget.component';

const exports = [
  UiLoadWidgetDirective,
  UiWidgetComponent,
  UiWidgetHeaderComponent,
  UiWidgetBodyComponent,
  UiWidgetSectionComponent,
  UiWidgetErrorComponent,
];

@NgModule({
  declarations: exports,
  imports: [CommonModule, NzAlertModule, UiDirectivesModule],
  exports: exports,
})
export class UiWidgetModule {}
