import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { WINDOW, objCopy } from '@base-frontend/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { IPage, IWidget, IWidgetLayout, IWidgetLayoutItem } from './symbols';

@UntilDestroy()
@Component({
  selector: 'ui-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.less'],
})
export class UiPageComponent implements OnChanges {
  @Input('uiPage') page: IPage;
  @Input('uiGridLanes') gridLanes = 24;
  @Input('uiPageNotFoundTemplate') pageNotFoundTemplate: TemplateRef<any>;
  @Input('uiShowPageNotFound') showPageNotFound: boolean;
  @Input('uiLoading') loading: boolean;
  @Input('uiLoadingText') loadingText: string;
  @Input('uiTrackBy') trackBy = (index: number, widget: IWidget) => widget.Type;
  @Input('uiLayout') layoutInput: IWidgetLayout;
  @Output('uiLayoutChange') layoutChange = new EventEmitter<IWidgetLayout>();

  dragHandleClass = 'ui-widget-header';
  widgetDialogOpen: boolean;
  widgetLayout: IWidgetLayout = {};

  private _widgetLayoutSnapshot: IWidgetLayout = {};
  private _collator = new Intl.Collator(undefined, { numeric: true });

  widgets: IWidgetVM = {
    grid: [],
    top: [],
    right: [],
    bottom: [],
    left: [],
  };

  gridLayoutChange$ = new Subject();

  constructor(
    private _cdr: ChangeDetectorRef,
    @Inject(WINDOW) private _window: Window,
  ) {
    this.gridLayoutChange$
      .pipe(debounceTime(100), untilDestroyed(this))
      .subscribe(() => this.layoutChange.emit(this.widgetLayout));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.page && this.page) {
      this.binWidgets(this.page.Widgets);
      this.widgetLayout = this.getWidgetLayoutWithFallback();
    }

    if (changes.layoutInput && this.layoutInput) {
      this.widgetLayout = this.layoutInput;
      this.binNewWidgetsAddedToLayout();
    }
  }

  sortCompareFn = (a: IWidget, b: IWidget) => {
    return this._collator.compare(a.Title || a.Label, b.Title || b.Label);
  };

  private binWidgets(widgets: IWidget[]) {
    this.widgets.grid = [];
    this.widgets.top = [];
    this.widgets.right = [];
    this.widgets.bottom = [];
    this.widgets.left = [];

    widgets.forEach(widget => this.widgets[widget.Layout.Type].push(widget));
  }

  removeWidget(id: string) {
    if (!this.widgetLayout[id]) return;
    this.onWidgetToggle(id, false);
    this._cdr.markForCheck();
  }

  openWidgetsDialog() {
    this._widgetLayoutSnapshot = objCopy(this.widgetLayout);
    this.widgetDialogOpen = true;
    this._cdr.markForCheck();
  }

  onWidgetsDialogApply() {
    this.widgetDialogOpen = false;
    this._cdr.markForCheck();
  }

  onWidgetsDialogCancel() {
    this.widgetDialogOpen = false;
    this.widgetLayout = this._widgetLayoutSnapshot;
    this.layoutChange.emit(this.widgetLayout);
    this._cdr.markForCheck();
  }

  onWidgetToggle(id: string, visible: boolean) {
    this.widgetLayout[id].Visible = visible;
    this.widgetLayout[id].GridListItem.x = Number.MAX_SAFE_INTEGER;
    this.widgetLayout[id].GridListItem.y = Number.MAX_SAFE_INTEGER;
    this.widgetLayout[id].GridListItem.autoPosition = true;
    this.layoutChange.emit(this.widgetLayout);
  }

  resetWidgetLayout() {
    this.widgetLayout = this.getDefaultWidgetLayout();
  }

  private binNewWidgetsAddedToLayout() {
    Object.keys(this.widgetLayout)
      .filter(id => !this.isWidgetBinnedInGrid(id))
      .forEach(id => {
        const widget = this.page.Widgets.find(w => w.Id === id);
        if (widget) {
          this.widgets.grid.push(widget);
        }
      });
  }

  private getWidgetLayoutWithFallback() {
    const widgetLayout: IWidgetLayout = {};
    this.widgets.grid.forEach((widget, index) => {
      widgetLayout[widget.Id] =
        this.layoutInput?.[widget.Id] ?? this.createWidgetLayoutItem(widget, index);
    });
    return widgetLayout;
  }

  private getDefaultWidgetLayout() {
    const widgetLayout = {};
    this.widgets.grid.forEach((widget, index) => {
      widgetLayout[widget.Id] = this.createWidgetLayoutItem(widget, index);
    });
    return widgetLayout;
  }

  private createWidgetLayoutItem(widget: IWidget, order: number): IWidgetLayoutItem {
    return {
      Visible: true,
      GridListItem: {
        x: widget.Layout.X,
        y: widget.Layout.Y,
        w: widget.Layout.W,
        h: widget.Layout.H,
        autoSize: widget.Layout.AutoSize,
        autoPosition: widget.Layout.AutoPosition,
        order,
      },
    };
  }

  private isWidgetBinnedInGrid(id: string) {
    return !!this.widgets.grid.find(w => w.Id === id);
  }
}

interface IWidgetVM {
  grid: IWidget[];
  top: IWidget[];
  right: IWidget[];
  bottom: IWidget[];
  left: IWidget[];
}
