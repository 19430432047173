import { Pipe, PipeTransform } from '@angular/core';
import { msToDuration } from '@base-frontend/core';

@Pipe({
  name: 'uiMsToDuration',
})
export class UiMsToDurationPipe implements PipeTransform {
  transform(
    ms: number,
    minUnit: 'd' | 'h' | 'm' | 's' = 's',
    maxUnit: 'd' | 'h' | 'm' | 's' = 'd',
    displayWhenNull = '',
    displayWhenZero = '',
  ): string {
    return msToDuration(ms, minUnit, maxUnit, displayWhenNull, displayWhenZero);
  }
}
