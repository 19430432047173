import { ModuleWithProviders, NgModule } from '@angular/core';

// Module that does nothing. To be used to conditionally import modules with forRoot.
// For example (environment.oidcSettings ? OidcModule : NullModule).forRoot(environment.oidcSettings))
@NgModule({})
export class NullModule {
  static forRoot(): ModuleWithProviders<NullModule> {
    return {
      ngModule: NullModule,
    };
  }
}
