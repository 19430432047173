import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import { fromMutation } from './from-mutation';

/**
 * Will wait until at least one element contains given class in root element
 * Returns an array of HTMLElements that contains given class.
 * <p><b>Example:</b> of('class-name').pipe(findElementsContainingClass(rootElement));</p>
 *
 * @param {HTMLElement} rootEl The element from which to search
 */
export function findElementsContainingClass(rootEl: HTMLElement) {
  return function (source: Observable<string>): Observable<HTMLElement[]> {
    return source.pipe(
      distinctUntilChanged(),
      switchMap(className => {
        const elements = queryElements(rootEl, className);

        if (elements.length) {
          return of(elements);
        }

        // Wait until at least one element containing given class exists in root element
        return fromMutation(rootEl, {
          subtree: true,
          childList: true,
          attributes: true,
        }).pipe(
          debounceTime(50),
          map(() => queryElements(rootEl, className)),
          filter(els => els.length > 0),
          take(1),
        );
      }),
    );
  };
}

function queryElements(rootEl: HTMLElement, className: string) {
  return Array.from(rootEl.getElementsByClassName(className)) as HTMLElement[];
}
