import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IPage, UiPageModule, UiPageNotFoundModule, UiWidgetModule } from '@base-frontend/ui';
import { IBlankWidget } from '../../widgets/blank/symbols';
import { INavbarWidget } from '../../widgets/navbar/symbols';

@Component({
  selector: 'pr-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.less'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, UiPageNotFoundModule, UiWidgetModule, UiPageModule],
})
export class PageNotFoundComponent {
  navbarInputs: Partial<INavbarWidget> = {
    Id: 'navbar',
    Type: 'navbar',
    Layout: { Type: 'top' },
    HideHeader: true,
    Breadcrumbs: [],
  };

  page = <IPage>{
    PageId: null,
    Widgets: [
      <INavbarWidget>{
        Id: 'navbar',
        Type: 'navbar',
        Layout: { Type: 'top' },
        HideHeader: true,
        Breadcrumbs: [],
      },
      <IBlankWidget>{
        Id: '0',
        Type: 'blank',
        HideHeader: true,
        Layout: { Type: 'top' },
        Height: 'calc(100vh - 44px)',
      },
    ],
  };
}
