import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface IUiWidgetComponent {
  inputs$: Observable<any>;
}

export interface IUiWidgetRegistry {
  [widgetType: string]: () => Promise<any>;
}

export const UI_WIDGET_REGISTRY = new InjectionToken<IUiWidgetRegistry>('UI_WIDGET_REGISTRY');
