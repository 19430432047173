import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@base-frontend/core';

@Injectable({
  providedIn: 'root',
})
export class SigninCallbackRedirect {
  constructor(
    private _router: Router,
    private _authService: AuthService,
  ) {}

  async canActivate() {
    await this._authService.signInCallback();
    return this._router.parseUrl(this._authService.getFromPath());
  }
}
