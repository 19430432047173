import { IUiWidgetRegistry } from '@base-frontend/ui';

export function registerWidgets(): IUiWidgetRegistry {
  return {
    blank: () => import('./blank/blank.module').then(m => m.BlankModule),
    gauge360: () => import('./gauge360/gauge360.module').then(m => m.Gauge360Module),
    gauge12: () => import('./gauge12/gauge12.module').then(m => m.Gauge12Module),
    gauge14: () => import('./gauge14/gauge14.module').then(m => m.Gauge14Module),
    gauge34: () => import('./gauge34/gauge34.module').then(m => m.Gauge34Module),
    navbar: () => import('./navbar/navbar.module').then(m => m.NavbarModule),
    'tool-diagram': () =>
      import('./tool-diagram/tool-diagram.module').then(m => m.ToolDiagramModule),
    'sensor-diagram': () =>
      import('./sensor-diagram/sensor-diagram.module').then(m => m.SensorDiagramModule),
    status: () => import('./status/status.module').then(m => m.StatusModule),
    'vib-vert-histogram': () =>
      import('./vib-vert-histogram/vib-vert-histogram.module').then(m => m.VibVertHistogramModule),
    'job-info': () => import('./job-info/job-info.module').then(m => m.JobInfoModule),
    'job-summary': () => import('./job-summary/job-summary.module').then(m => m.JobSummaryModule),
    'chart-tracks': () =>
      import('./chart-tracks/chart-tracks.module').then(m => m.ChartTracksModule),
    efficiency: () => import('./efficiency/efficiency.module').then(m => m.EfficiencyModule),
    radar: () => import('./radar/radar.module').then(m => m.RadarModule),
    'time-gauge': () => import('./time-gauge/time-gauge.module').then(m => m.TimeGaugeWidgetModule),
    'battery-usage': () =>
      import('./battery-usage/battery-usage.module').then(m => m.BatteryUsageModule),
    'battery-voltage': () =>
      import('./battery-voltage/battery-voltage.module').then(m => m.BatteryVoltageModule),
    image: () => import('./image/image.module').then(m => m.ImageModule),
    statistic: () => import('./statistic/statistic.module').then(m => m.StatisticModule),
  };
}
