import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uiSort',
})
export class UiSortPipe implements PipeTransform {
  transform<T>(arr: T[], compareFn: (a: T, b: T) => number): T[] {
    if (!Array.isArray(arr) || !compareFn) return arr;
    return [...arr].sort(compareFn);
  }
}
