import { DragDrop, DragRef } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { clamp, findElementsContainingClass, WINDOW } from '@base-frontend/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, fromEvent, Subject, Subscription, timer } from 'rxjs';
import {
  debounceTime,
  filter,
  finalize,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { GridListService } from './grid-list.service';
import { IUiGridListDrag, IUiGridListItem } from './symbols';

@UntilDestroy()
@Component({
  selector: 'ui-grid-list-item',
  templateUrl: 'grid-list-item.component.html',
  styleUrls: ['./grid-list-item.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ui-grid-list-item',
    '[class.ui-grid-list-item-dragging]': 'dragRef.isDragging()',
    '[class.ui-grid-list-item-resizing]': 'resizing',
  },
})
export class UiGridListItemComponent implements OnChanges, OnInit, OnDestroy {
  @Input() uiOptions: IUiGridListItem;
  @Input() uiDragHandleClass = 'drag-handle';
  @Output() uiChange = new EventEmitter<IUiGridListItem>();
  @ViewChild('resizeHandle', { static: true }) resizeHandleElRef: ElementRef<HTMLElement>;

  resizing: boolean;
  dragRef: DragRef;
  dragHandleClass$ = new BehaviorSubject<string>(this.uiDragHandleClass);
  private _el = this._elRef.nativeElement;
  private _positionUpdateCount = 0;

  constructor(
    @Inject(WINDOW) private _window: Window,
    private _elRef: ElementRef<HTMLElement>,
    private _dragDrop: DragDrop,
    private _renderer: Renderer2,
    private _ngZone: NgZone,
    private _gridListService: GridListService,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.uiDragHandleClass) {
      this.dragHandleClass$.next(this.uiDragHandleClass);
    }

    if (changes.uiOptions && !changes.uiOptions.firstChange) {
      this._gridListService.itemUpdate$.next(this);
    }
  }

  ngOnInit() {
    this.subscribeToResizeEvents();
    this.subscribeToDragEvents();

    // When text is selected, the text gets dragged instead of the grid item
    this.clearTextSelectionOnMouseDown();

    this._gridListService.itemAdd$.next(this);

    this._ngZone.runOutsideAngular(() => {
      this.dragHandleClass$
        .pipe(findElementsContainingClass(this._el), untilDestroyed(this))
        .subscribe(targets => this.attachDragHandles(targets));
    });
  }

  ngOnDestroy() {
    this._gridListService.itemRemove$.next(this);
    this.dragRef.dispose();
  }

  updatePosition(left: number, top: number, width: number, height: number) {
    this._renderer.setStyle(this._el, 'width', width + 'px');
    this._renderer.setStyle(this._el, 'height', height + 'px');
    this._renderer.setStyle(this._el, 'transform', `translate3d(${left}px, ${top}px, 0px)`);

    if (this._positionUpdateCount < 2) {
      this._renderer.addClass(
        this._el,
        'ui-grid-list-item-update-' + (this._positionUpdateCount + 1),
      );
    }

    this._positionUpdateCount++;

    this.uiChange.emit(this.uiOptions);
  }

  getPosition() {
    const parent = this._el.parentElement.getBoundingClientRect();
    const self = this._el.getBoundingClientRect();
    return {
      left: self.left - parent.left,
      top: self.top - parent.top + this._el.parentElement.scrollTop,
    };
  }

  calcIntrinsicHeight$(cellWidth: number) {
    return timer(0, 50).pipe(
      map(() => this.calcIntrinsicHeight(cellWidth)),
      filter(height => height !== 0),
      // Wait for width and height to reset before adding transitions back in.
      // Otherwise, the element will animate when calculating the size.
      debounceTime(0),
      tap(() => this._renderer.removeClass(this._el, 'u-transition-none')),
      take(1),
    );
  }

  calcIntrinsicHeight(cellWidth: number) {
    const oldWidth = this._el.style.width;
    const oldHeight = this._el.style.height;

    this._renderer.addClass(this._el, 'u-transition-none');
    this._renderer.setStyle(this._el, 'width', this.uiOptions.w * cellWidth + 'px');
    this._renderer.setStyle(this._el, 'height', 'auto');

    const height = this._el.offsetHeight;

    this._renderer.setStyle(this._el, 'width', oldWidth);
    this._renderer.setStyle(this._el, 'height', oldHeight);

    return height;
  }

  private subscribeToDragEvents() {
    this.dragRef = this._dragDrop.createDrag(this._elRef);

    // Not using this.dragRef.moved, because it always runs inside ngZone
    let mouseMoveSub: Subscription;
    const mouseMove$ = fromEvent(this._window, 'mousemove').pipe(
      tap(() => {
        this._gridListService.dragMove$.next(this.buildDragEvent(this.dragRef));
      }),
    );

    this.dragRef.started.pipe(untilDestroyed(this)).subscribe(event => {
      this._ngZone.runOutsideAngular(() => {
        mouseMoveSub = mouseMove$.subscribe();
      });
      this._gridListService.dragStart$.next(this.buildDragEvent(event.source));
    });

    this.dragRef.beforeStarted.pipe(untilDestroyed(this)).subscribe(() => this.resetDragPosition());

    this.dragRef.ended.pipe(untilDestroyed(this)).subscribe(event => {
      mouseMoveSub.unsubscribe();
      this._gridListService.dragEnd$.next(this.buildDragEvent(event.source));
    });
  }

  private subscribeToResizeEvents() {
    const resizeHandleEl = this.resizeHandleElRef.nativeElement;

    const mouseDown$ = fromEvent<MouseEvent>(resizeHandleEl, 'mousedown');
    const mouseUp$ = fromEvent<MouseEvent>(this._window.document, 'mouseup');
    const mouseMove$ = fromEvent<MouseEvent>(this._window.document, 'mousemove');

    const resizeStart$ = mouseDown$.pipe(map(() => this._el.getBoundingClientRect()));
    const resizeEnd$ = new Subject();
    const resizeMove$ = resizeStart$.pipe(
      switchMap(rect =>
        mouseMove$.pipe(
          map(event => ({
            width: clamp(event.clientX - rect.left, 0, this._window.innerWidth - rect.left),
            height: clamp(event.clientY - rect.top, 0, this._window.innerHeight - rect.top),
          })),
          takeUntil(mouseUp$),
          finalize(() => resizeEnd$.next()),
        ),
      ),
    );

    resizeStart$.pipe(untilDestroyed(this)).subscribe(() => {
      this.toggleResizing(true);
      this._gridListService.resizeStart$.next(this);
    });

    resizeEnd$.pipe(untilDestroyed(this)).subscribe(() => {
      this.toggleResizing(false);
      this._ngZone.run(() => this._gridListService.resizeEnd$.next(this));
    });

    this._ngZone.runOutsideAngular(() => {
      resizeMove$.pipe(untilDestroyed(this)).subscribe(size => {
        this._gridListService.resizeMove$.next({ component: this, ...size });
      });
    });
  }

  private toggleResizing(resizing: boolean) {
    this.resizing = resizing;
    this._cdr.detectChanges();
  }

  private clearTextSelectionOnMouseDown() {
    this._ngZone.runOutsideAngular(() => {
      fromEvent(this._el, 'mousedown')
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this._window.document?.getSelection()?.empty();
        });
    });
  }

  private buildDragEvent(source: DragRef): IUiGridListDrag {
    return {
      component: this,
      source,
    };
  }

  private attachDragHandles(elements: HTMLElement[]) {
    this.dragRef?.withHandles(elements);
  }

  private resetDragPosition() {
    // Hack: We need to manually set the _initialTransform, because we're modifying the
    // transform via the GridList
    // @ts-ignore
    this.dragRef._initialTransform = this._el.style.transform || '';
    this.dragRef.reset();
  }
}
