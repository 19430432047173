import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Injector } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ConfigService } from '../config/config.service';
import { LoggingService } from '../logging/logging.service';
import { MessageHubService } from '../message-hub/message-hub.service';
import { ThemeService } from '../theme/theme.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  constructor(
    private _configService: ConfigService,
    private _messageHub: MessageHubService,
    // private _windowManager: WindowManagerService,
    private _loggingService: LoggingService,
    private _injector: Injector,
    private _themeService: ThemeService,
    @Inject(DOCUMENT) private _document: Document,
  ) {}

  private get _notificationService() {
    return this._injector.get(NzNotificationService);
  }

  async initialize() {
    try {
      this._themeService.loadTheme();
      await this._configService.load();
      await this._messageHub.startup();
      // TODO RK: Fix window manager for prod builds
      // this._windowManager.init();
      this._document.body.classList.add('app-initialized');
    } catch (e) {
      this._loggingService.logError(e);
      this._notificationService.error(
        'Failed to initialize application. Please try refreshing the page. If the error still persists, please contact support.',
        '',
        { nzDuration: 0 },
      );
    }
  }
}
