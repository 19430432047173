@if (!showPageNotFound) {
  <div class="template-layout">
    <div class="template-dock-top">
      @for (widget of widgets.top; track trackBy($index, widget)) {
        <div>
          <ng-container
            *ngTemplateOutlet="widgetTemplate; context: { $implicit: widget }"
          ></ng-container>
        </div>
      }
    </div>
    <div class="template-dock-left">
      @for (widget of widgets.left; track trackBy($index, widget)) {
        <div>
          <ng-container
            *ngTemplateOutlet="widgetTemplate; context: { $implicit: widget }"
          ></ng-container>
        </div>
      }
    </div>
    <div class="template-dock-right">
      @for (widget of widgets.right; track trackBy($index, widget)) {
        <div>
          <ng-container
            *ngTemplateOutlet="widgetTemplate; context: { $implicit: widget }"
          ></ng-container>
        </div>
      }
    </div>
    <div class="template-dock-bottom">
      @for (widget of widgets.bottom; track trackBy($index, widget)) {
        <div>
          <ng-container
            *ngTemplateOutlet="widgetTemplate; context: { $implicit: widget }"
          ></ng-container>
        </div>
      }
    </div>

    <div class="template-widgets">
      <div class="template-widgets-inner">
        <nz-spin [nzSpinning]="loading" [nzTip]="loadingText">
          @if (widgets.grid.length) {
            <ui-grid-list [uiLanes]="gridLanes">
              @for (widget of widgets.grid; track trackBy(i, widget); let i = $index) {
                @if (widgetLayout[widget.Id]?.Visible) {
                  <ui-grid-list-item
                    [uiDragHandleClass]="this.dragHandleClass"
                    [uiOptions]="widgetLayout[widget.Id].GridListItem"
                    (uiChange)="gridLayoutChange$.next()"
                  >
                    <ng-container
                      #uiLoadWidget="uiLoadWidget"
                      [uiInputs]="widget"
                      [uiLoadWidget]="widget.Type"
                    ></ng-container>
                    @if (uiLoadWidget.failedToLoad$ | async) {
                      <ui-widget-error
                        [uiMessage]="'Widget &ldquo;' + widget.Type + '&rdquo; was not found.'"
                      ></ui-widget-error>
                    }
                  </ui-grid-list-item>
                }
              }
            </ui-grid-list>
          }
        </nz-spin>
      </div>
    </div>
  </div>
} @else {
  @if (pageNotFoundTemplate != null) {
    <ng-container [ngTemplateOutlet]="pageNotFoundTemplate" />
  } @else {
    <ui-page-not-found></ui-page-not-found>
  }
}

<ng-template #widgetTemplate let-widget>
  <ng-container
    #uiLoadWidget="uiLoadWidget"
    [uiInputs]="widget"
    [uiLoadWidget]="widget.Type"
  ></ng-container>
  @if (uiLoadWidget.failedToLoad$ | async) {
    <ui-widget-error
      [uiMessage]="'Widget &ldquo;' + widget.Type + '&rdquo; was not found.'"
    ></ui-widget-error>
  }
</ng-template>

<nz-modal
  nzCancelText="Cancel"
  nzOkText="Apply"
  nzTitle="Widget Selection"
  nzWidth="400px"
  [nzKeyboard]="false"
  [nzMaskClosable]="false"
  [(nzVisible)]="widgetDialogOpen"
  (nzOnCancel)="onWidgetsDialogCancel()"
  (nzOnOk)="onWidgetsDialogApply()"
>
  <ng-template nzModalContent>
    <div class="template-modal-header">
      <h3 class="u-mb-none">Available Widgets</h3>
      <a title="Reset all widgets to default settings" (click)="resetWidgetLayout()">Reset</a>
    </div>
    @for (widget of widgets.grid | uiSort: sortCompareFn; track widget) {
      @if (widget.Id) {
        <label
          class="template-modal-checkbox"
          nz-checkbox
          [ngModel]="widgetLayout[widget.Id]?.Visible"
          (ngModelChange)="onWidgetToggle(widget.Id, $event)"
        >
          {{ widget.Title || widget.Label }}
        </label>
      }
    }
  </ng-template>
</nz-modal>
