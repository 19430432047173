import { animate, AnimationMetadata, style, transition } from '@angular/animations';
import { Directive, Input, NgZone, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { timer } from 'rxjs';

@UntilDestroy()
@Directive({
  selector: '[uiRenderDelay]',
})
export class UiRenderDelayDirective implements OnInit {
  // Delay in milliseconds
  @Input('uiRenderDelay') delay: number;

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainerRef: ViewContainerRef,
    private _ngZone: NgZone,
  ) {}

  ngOnInit() {
    timer(this.delay || 0)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this._viewContainerRef
          .createEmbeddedView(this._templateRef, { $implicit: this.delay, delay: this.delay })
          .detectChanges();
      });
  }
}
