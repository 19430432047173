import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError, mergeMap, multicast, refCount } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  private _retrieveToken$ = this._authService.getAuthTokenWithRetry(3).pipe(
    multicast(() => new ReplaySubject(1)),
    refCount(),
  );

  constructor(private _authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const attachToken = token =>
      request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });

    return this._retrieveToken$.pipe(
      catchError(this.goToLoginPage),
      mergeMap(token => next.handle(attachToken(token))),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return this.goToLoginPage();
        }
        throw error;
      }),
    );
  }

  private goToLoginPage = () => {
    this._authService.login();
    return EMPTY;
  };
}
