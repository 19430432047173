import { Inject, Injectable } from '@angular/core';
import { User, UserManager } from 'oidc-client-ts';
import { defer, from, of } from 'rxjs';
import { map, mergeMap, retry } from 'rxjs/operators';
import { WINDOW } from '../core.symbols';
import { IOidcSettings, OIDC_SETTINGS } from './symbols';

const FROM_PATH_KEY = 'OIDCFromPath';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _userManager = new UserManager(this._settings);

  constructor(
    @Inject(WINDOW) private _window: Window,
    @Inject(OIDC_SETTINGS) private _settings: IOidcSettings,
  ) {}

  get signinCallbackPath() {
    return this._settings.redirect_uri.replace(this._window.location.origin, '');
  }

  getUser() {
    return this._userManager.getUser();
  }

  login() {
    this.saveFromPath();
    return this._userManager.signinRedirect();
  }

  renewToken() {
    return this._userManager.signinSilent();
  }

  logout() {
    return this._userManager.signoutRedirect();
  }

  signInCallback() {
    return this._userManager.signinCallback();
  }

  getAuthToken() {
    return this._userManager.getUser().then(user => user?.access_token);
  }

  getAuthTokenWithRetry(retryCount: number) {
    const renewToken$ = defer(() =>
      from(this.renewToken()).pipe(
        map(user => {
          if (!isTokenValid(user)) throw new Error('Unable to get token.');
          return user.access_token;
        }),
        retry(retryCount),
      ),
    );

    return defer(() => from(this.getUser())).pipe(
      mergeMap(user => (isTokenValid(user) ? of(user.access_token) : renewToken$)),
    );
  }

  getFromPath() {
    return this._window.sessionStorage.getItem(FROM_PATH_KEY) || '/';
  }

  private saveFromPath() {
    this._window.sessionStorage.setItem(FROM_PATH_KEY, location.pathname);
  }
}

function isTokenValid(user: User) {
  return user && !user.expired;
}
