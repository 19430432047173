import { isNil } from '@base-frontend/core';
import {
  IUiGridListItem as IItem,
  IUiGridListItemSize as ISize,
  IUiGridListOptions as IOptions,
} from './symbols';

export class GridList {
  grid = [];

  constructor(private _items: IItem[], private _options: IOptions) {
    this.fixInvalidItems();
    this.autoPositionItems(_items.filter(item => item.autoPosition));

    for (const item of _items) {
      this.resolveCollisions(item);
    }

    if (this._options.floatUp) {
      this.floatItemsUp();
    }
  }

  static cloneItems(items: IItem[]) {
    const clone = [];

    for (let i = 0; i < items.length; i++) {
      if (!clone[i]) {
        clone[i] = {};
      }

      for (const k in items[i]) {
        if (items[i].hasOwnProperty(k)) {
          clone[i][k] = items[i][k];
        }
      }
    }

    return clone;
  }

  static isValid(item: IItem) {
    return !isNil(item.x) && !isNil(item.y) && item.w && item.h;
  }

  moveItemToPosition(item: IItem, [x, y]: number[]) {
    item.x = x;
    item.y = y;
    this.resolveCollisions(item);

    if (this._options.floatUp) {
      this.floatItemsUp();
    }
  }

  resizeItem(item: IItem, size: ISize) {
    item.w = size.w;
    item.h = size.h;
    this.resolveCollisions(item);

    if (this._options.floatUp) {
      this.floatItemsUp();
    }
  }

  autoPosition(item: IItem) {
    let row = 0;

    while (true) {
      item.y = row;

      for (let col = 0; col + item.w <= this._options.lanes; col++) {
        item.x = col;

        if (!this.hasCollisions(item)) {
          return;
        }
      }

      row++;
    }
  }

  private fixInvalidItems() {
    const itemsToPosition = [];

    this._items.forEach(item => {
      if (!GridList.isValid(item)) {
        item.w = item.w || this._options.defaultItemWidth;
        item.h = item.h || this._options.defaultItemHeight;
        item.x = 0;
        item.y = 0;
        itemsToPosition.push(item);
      }
    });

    this.autoPositionItems(itemsToPosition);
  }

  private autoPositionItems(items: IItem[]) {
    // Set x and y to a large number so that there are no collisions at [0,0].
    // This way the items are positioned in the correct order.
    items.forEach(item => {
      item.x = Number.POSITIVE_INFINITY;
      item.y = Number.POSITIVE_INFINITY;
      item.autoPosition = false;
    });
    [...items].forEach(item => this.autoPosition(item));
  }

  private floatItemsUp() {
    this.getSortedItems().forEach(item => this.floatUp(item));
  }

  private floatUp(item: IItem) {
    while (!this.hasCollisions(item) && item.y > 0) {
      item.y -= 1;
    }

    if (this.hasCollisions(item)) {
      item.y += 1;
    }
  }

  private resolveCollisions(item: IItem) {
    const collidingItems = this.getItemsCollidingWithItem(item);

    if (!collidingItems.length) {
      return;
    }

    // Move colliding items
    for (const collidingItem of collidingItems) {
      if (this.hasCollisions(collidingItem)) {
        collidingItem.y = item.y + item.h;
        this.resolveCollisions(collidingItem);
      }
    }
  }

  private hasCollisions(target: IItem) {
    return this._items.some(item => item !== target && itemsColliding(target, item));
  }

  private getItemsCollidingWithItem(target: IItem) {
    return this._items.filter(item => item !== target && itemsColliding(target, item));
  }

  private getSortedItems() {
    return [...this._items].sort((a, b) => a.x - b.x).sort((a, b) => a.y - b.y);
  }
}

function itemsColliding(a: IItem, b: IItem) {
  return !(b.x >= a.x + a.w || b.x + b.w <= a.x || b.y >= a.y + a.h || b.y + b.h <= a.y);
}
