export function clamp(value: number, min: number, max: number) {
  if (value < min) {
    return min;
  }
  if (value > max) {
    return max;
  }
  return value;
}

export function isInfinity(value: number) {
  return Math.abs(value) === Infinity;
}

export function minDistBetweenAngles(a: number, b: number) {
  if (typeof a !== 'number' || typeof b !== 'number') {
    return null;
  }

  const clockwiseDist = a > b ? 360 - a + b : b - a;
  const counterClockwiseDist = a > b ? a - b : 360 - b + a;
  return Math.min(clockwiseDist, counterClockwiseDist);
}

export function sum(values: number[]) {
  return values.reduce((total, value) => total + value, 0);
}

export function digitsBeforeDecimal(value: number) {
  const strValue = value.toString();
  return strValue.split('.')[0]?.length || strValue.length;
}

export function digitsAfterDecimal(value: number) {
  return value.toString().split('.')[1]?.length || 0;
}
