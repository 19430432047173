import { NgZone } from '@angular/core';
import { IMessage } from '@base-frontend/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, mapTo, skip } from 'rxjs/operators';
import { LoggingService } from '../logging/logging.service';

export class SignalR {
  private _received$ = new Subject<IMessage>();
  received$ = this._received$.asObservable();

  private _isConnected$ = new BehaviorSubject<boolean>(false);
  isConnected$ = this._isConnected$.pipe(distinctUntilChanged());
  serverRestart$: Observable<void> = this.isConnected$.pipe(
    filter(isConnected => isConnected),
    skip(1),
    mapTo(null),
  );

  // Will retry connecting to the server every _retryTime (ms).
  // If retry fails, then _retryTime will be incremented by _retryIncrement.
  // If retry succeeds, then _retryTime will be reset to _defaultRetryTime
  private _defaultRetryTime = 5000;
  private _maxRetryTime = 60000;
  private _retryIncrement = 5000;
  private _retryTime = this._defaultRetryTime;

  private _hubConnection: HubConnection;

  constructor(private _ngZone: NgZone, private _logger: LoggingService, private _url: string) {}

  startup(): Promise<void> {
    return this._ngZone.runOutsideAngular(() => {
      this.createConnection();
      this.registerOnServerEvents();
      return this.connect();
    });
  }

  private createConnection() {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(this._url, )
      .build();

    this._hubConnection.onclose(() => {
      this._logger.logError(`SignalR: lost connection to ${this._url}, retrying...`);
      this.connect();
    });
  }

  private connect(): Promise<void> {
    return this._hubConnection
      .start()
      .then(() => {
        this._logger.logSuccess(`SignalR: connected to ${this._url}`);
        this._isConnected$.next(true);
        this._retryTime = this._defaultRetryTime;
      })
      .catch(error => {
        this._isConnected$.next(false);
        this._retryTime = Math.min(this._maxRetryTime, this._retryTime + this._retryIncrement);
        this._logger.logError(
          `SignalR: error while establishing connection to ${this._url} (next retry in ${
            this._retryTime / 1000
          }s)`,
        );
        setTimeout(() => this.connect(), this._retryTime);
      });
  }

  private registerOnServerEvents(): void {
    this._hubConnection.on('Notify', data => {
      console.log(data);
      if (data.Key) {
        this._ngZone.run(() => this._received$.next(data));
      }
    });

    this._hubConnection.on('MessageClient', data => {
      console.log(data);
      if (data.Key) {
        this._ngZone.run(() => this._received$.next(data));
      }
    });
  }
}
