import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { WINDOW } from '../../core.symbols';
import { generateId } from '../../utilities';
import { CONFIG_FILE_PATH } from './symbols';

export interface IConfigServer {
  File?: string;
  SignalR?: string;
  Api: string;
}

export interface IConfig {
  Servers: { [name: string]: IConfigServer };
  ClientId: string;
  DeviceId: string;
  BlockDataServer: string;
  DateTimeFormat: string;
  ForTest: boolean;
}

const DEFAULT_CONFIG = {
  Servers: {},
  ClientId: '',
  DeviceId: '',
  BlockDataServer: '',
  DateTimeFormat: 'dd-MMM-yy HH:mm:ss',
  ForTest: false,
};

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  readonly fileUrl = 'assets/config.json';
  config: IConfig;

  constructor(
    @Inject(PLATFORM_ID) private _platformId,
    @Inject(WINDOW) private _window: Window,
    @Inject(CONFIG_FILE_PATH) private _configFilePath: string,
    private _http: HttpClient,
  ) {}

  load(): Promise<IConfig> {
    const params = new URLSearchParams(this._window.location.search);

    return this._http
      .get(this._configFilePath)
      .toPromise()
      .then(this.replaceLocalhostWithHostname)
      .then((config: IConfig) => {
        this.config = {
          ...DEFAULT_CONFIG,
          ...(config || {}),
          ...this.getIds(),
          ForTest: params.has('test'),
        };
        return this.config;
      });
  }

  private getIds() {
    if (isPlatformServer(this._platformId)) {
      return {
        ClientId: generateId(),
        DeviceId: generateId(),
      };
    }

    const ids = {
      ClientId: window.sessionStorage.getItem('ClientId') || generateId(),
      DeviceId: window.localStorage.getItem('DeviceId') || generateId(),
    };
    window.sessionStorage.setItem('ClientId', ids.ClientId);
    window.localStorage.setItem('DeviceId', ids.DeviceId);

    return ids;
  }

  private replaceLocalhostWithHostname = (config: IConfig) => {
    return JSON.parse(JSON.stringify(config).replace(/localhost/g, this._window.location.hostname));
  };
}
