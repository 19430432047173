import { AfterViewInit, Directive, ElementRef, Host, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';

@UntilDestroy()
@Directive({
  selector: '[uiRevertToWhenEmpty]',
  exportAs: 'uiRevertToWhenEmpty',
})
export class UiRevertToWhenEmptyDirective implements AfterViewInit {
  @Input() uiRevertToWhenEmpty: '@prev' | any = '@prev';

  get el() {
    return this._elRef.nativeElement;
  }

  constructor(
    private _elRef: ElementRef<Element>,
    @Host() @Self() @Optional() private _control: NgControl,
  ) {}

  ngAfterViewInit() {
    const input = (
      this.isNativeInputEl(this.el) ? this.el : this.el.querySelector('input')
    ) as HTMLInputElement;
    let prevValue: any = this._control.control.value;

    fromEvent(input, 'blur')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const value = this._control.control.value;

        if (this.isEmpty(value)) {
          const revertValue =
            this.uiRevertToWhenEmpty === '@prev' || this.uiRevertToWhenEmpty === ''
              ? prevValue
              : this.uiRevertToWhenEmpty;

          this._control.viewToModelUpdate(revertValue);
          this._control.control.setValue(revertValue);
        } else {
          prevValue = value;
        }
      });
  }

  isEmpty(value: any) {
    return value === '' || value === null || value === undefined;
  }

  isNativeInputEl(el: Element) {
    return el instanceof HTMLInputElement;
  }
}
