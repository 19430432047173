import { IPage } from '@base-frontend/ui';

export interface ITemplate {
  TemplateId: string;
  Pages: IPage[];
}

export interface IPageQuery extends IPageQueryPayload {
  Key: 'Page';
}

export enum EDiagramType {
  Component = 'component',
  Tool = 'tool',
  BHA = 'bha',
}

export interface IPageQueryPayload {
  TemplateId: string;
  PageId: string;
  Tool: string;
  DiagramType: EDiagramType;
  Sensors: string[];
  Components: string[];
}

export const PAGE_QUERY_DEFAULTS: Partial<IPageQueryPayload> = {
  PageId: 'BHA',
  Tool: 'BHA',
  DiagramType: EDiagramType.BHA,
  Sensors: [],
  Components: [],
};

export interface IMetaData {
  [key: string]: any;
}
