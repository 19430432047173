import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NzNotificationModule, NzNotificationService } from 'ng-zorro-antd/notification';
import { AppInitializerService } from './services';

export function initializeFactory(initializer: AppInitializerService) {
  return () => initializer.initialize();
}

const exportedModules = [CommonModule, BrowserAnimationsModule];

@NgModule({
  imports: [HttpClientModule, ...exportedModules, NzNotificationModule],
  exports: [...exportedModules],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        NzNotificationService,
        {
          provide: APP_INITIALIZER,
          useFactory: initializeFactory,
          deps: [AppInitializerService],
          multi: true,
        },
      ],
    };
  }
}
