import {
  Component,
  ComponentFactoryResolver,
  Injector,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NzConfig } from 'ng-zorro-antd/core/config';

export const nzConfigFactory = (
  injector: Injector,
  resolver: ComponentFactoryResolver,
): NzConfig => {
  const factory = resolver.resolveComponentFactory(NzGlobalTemplatesComponent);
  const { nzIndicator } = factory.create(injector).instance;
  return {
    spin: {
      nzIndicator,
    },
    notification: {
      nzPauseOnHover: true,
      nzDuration: 4000,
    },
    button: {
      nzSize: 'small',
    },
    card: {
      nzSize: 'small',
    },
    table: {
      nzLoadingIndicator: nzIndicator,
    },
  };
};

@Component({
  template: `
    <ng-template #nzIndicatorT>
      <i class="ant-spin-dot" style="font-size: 1.5em" nz-icon nzType="loading"></i>
    </ng-template>
  `,
})
export class NzGlobalTemplatesComponent {
  @ViewChild('nzIndicatorT', { static: true }) nzIndicator!: TemplateRef<void>;
}
